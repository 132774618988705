<template>
  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState == 'Update'">
          Update Modelslistings #{{ formData.id }}
        </div>
        <div v-if="formState == 'Create'">Create Modelslistings</div>
      </template>

      <EditModelslistings v-if="formState == 'Update'" :key="formKey" :data="formData"
                          :factionsData="factionsData"
                          :gridApi="formGridApi" :modalFormId="formId" :usersData="usersData"
                          :zonesData="zonesData"
                          @close="closeForm"/>

      <CreateModelslistings v-if="formState == 'Create'" :key="formKey" :factionsData="factionsData"
                            :gridApi="formGridApi"
                            :modalFormId="formId" :usersData="usersData" :zonesData="zonesData"
                            @close="closeForm"/>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <div class="col-sm-12">
      <AgGridTable :key="tableKey" :cacheBlockSize="cacheBlockSize" :columnDefs="columnDefs"
                   :maxBlocksInCache="maxBlocksInCache"
                   :pagination="pagination" :paginationPageSize="paginationPageSize" :rowData="rowData"
                   :rowModelType="rowModelType"
                   :url="url" className="ag-theme-alpine" domLayout="autoHeight"
                   rowSelection="multiple" @gridReady="onGridReady">
        <template #header_buttons>
          <div v-if="!routeData.meta.hideCreate" class="btn btn-primary" @click="openCreate">
            <i class="fa fa-plus"></i> Nouveau
          </div>
        </template>
      </AgGridTable>
    </div>
  </div>
</template>

<script>




export default {
  name: "ModelslistingsView",
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    CustomFiltre: () => import("@/components/CustomFiltre.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
    CreateModelslistings: () => import("./CreateModelslistings.vue"),
    EditModelslistings: () => import("./EditModelslistings.vue"),
    AnalysesImports: () => import("./AnalysesImports.vue")
  },
  data() {
    return {
      formId: "modelslistings",
      formState: "",
      formData: {},
      formWidth: "xl",
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: "http://127.0.0.1:8000/api/modelslistings-Aggrid1",
      table: "modelslistings",
      zonesData: [],
      factionsData: ["Jour", "Nuit"],
      usersData: [],
      requette: 1,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 100,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
    };
  },

  computed: {
    routeData: function () {
      let router = {meta: {}};
      if (window.router) {
        try {
          router = window.router;
        } catch (e) {
        }
      }

      return router;
    },
    taille: function () {
      let result = "col-sm-12";
      if (this.filtre) {
        result = "col-sm-9";
      }
      return result;
    },
  },
  watch: {
    routeData: {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null);
        this.gridApi.refreshServerSide();
      },
      deep: true,
    },
  },
  created() {
    (this.url = this.axios.defaults.baseURL + "/api/modelslistings-Aggrid1"),
        (this.formId = this.table + "_" + Date.now());
    this.rowBuffer = 0;
    this.rowModelType = "serverSide";
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;
  },
  beforeMount() {
    this.columnDefs = [
      {
        field: null,
        headerName: "",
        suppressCellSelection: true,
        minWidth: 80, maxWidth: 80,
        pinned: "left",
        cellRendererSelector: (params) => {
          return {
            component: "AgGridBtnClicked",
            params: {
              clicked: (field) => {
                this.showForm("Update", field, params.api);
              },
              render: `<div class="btn btn-primary  d-flex justify-content-center align-items-center" style="width:100%;height:100%;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`,
            },
          };
        },
      },
      {
        field: 'modelslistings.id',
        headerName: "",
        suppressCellSelection: true,
        hide: true
      },

      {
        field: 'modelslistings.date_debut',
        headerName: "",
        suppressCellSelection: true,
        hide: true
      },


      {
        headerName: 'zone',
        field: 'zones.libelle',

        sortable: true,
        suppressColumnsToolPanel: true,
        filterParams: {suppressAndOrCondition: true},
        join: {
          table: 'zones',
          champ2: 'zones.id',
          champ1: 'modelslistings.zone_id',
          operateur: '=',
        }
      },
      {

        headerName: "zone",
        field: "zones.id",
        valueFormatter: (params) => {
          let retour = "";
          try {
            return params.data["zone"]["Selectlabel"];
          } catch (e) {
          }
          return retour;
        },
        filter: "FiltreEntete",
        filterParams: {
          url: this.axios.defaults.baseURL + '/api/zones-Aggrid1',
          columnDefs: [
            {
              field: "",
              sortable: true,
              filter: "agTextColumnFilter",
              filterParams: {suppressAndOrCondition: true},
              headerName: "",
              cellStyle: {fontSize: '11px'},
              valueFormatter: (params) => {
                let retour = "";
                try {
                  return `${params.data["Selectlabel"]}`;
                } catch (e) {
                }
                return retour;
              },
            },
          ],
          filterFields: ['libelle'],
        },
      },

      {
        field: "modelslistings.Libelle",
        sortable: true,
        filter: "agTextColumnFilter",
        filterParams: {suppressAndOrCondition: true},
        headerName: "Libelle",
        floatingFilter: true,
      },

      {
        field: "modelslistings.min_partage",
        sortable: true,
        filter: "agTextColumnFilter",
        filterParams: {suppressAndOrCondition: true},
        headerName: "Nbrs Pts Requis",
        maxWidth: 150,
      },


      {
        field: "horaires",
        sortable: true,
        maxWidth: 150,
        headerName: "Nbrs Postes",
        valueFormatter: (params) => {
          let retour = "";
          try {
            const horairesArray = params.data["horaires"].split(',').filter(function (el) {
              return el != null && el != "" && el !== '-1';
            });
            retour = horairesArray.length;
          } catch (e) {
          }
          return retour;
        },
      },
      {
        field: "directions",
        sortable: true,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        headerName: 'valideur1',
        field: 'user.nom',
        sortable: true,
        floatingFilter: true,
        filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
        join: {
          table: 'users',
          tableAlias: 'user',
          champ2: 'users.id',
          champ1: 'modelslistings.user_id',
          operateur: '=',
        }
      },
      {
        headerName: 'valideur1',
        field: 'user2.nom',
        floatingFilter: true,
        sortable: true,

        filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
        join: {
          table: 'users',
          tableAlias: 'user2',
          champ2: 'users.id',
          champ1: 'modelslistings.user_id_2',
          operateur: '=',
        }
      },
      {
        headerName: 'valideur2',
        field: 'user3.nom',
        floatingFilter: true,
        sortable: true,

        filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
        join: {
          table: 'users',
          tableAlias: 'user3',
          champ2: 'users.id',
          champ1: 'modelslistings.user_id_3',
          operateur: '=',
        }
      },
      {
        headerName: 'valideur2',
        field: 'user4.nom',
        floatingFilter: true,
        sortable: true,

        filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
        join: {
          table: 'users',
          tableAlias: 'user4',
          champ2: 'users.id',
          champ1: 'modelslistings.user_id_4',
          operateur: '=',
        }
      },
      {
        headerName: 'valideur1',
        field: 'user.prenom',
        sortable: true,
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        headerName: 'valideur1',
        field: 'user2.prenom',
        sortable: true,
        hide: true,
        suppressColumnsToolPanel: true,
      },

      {
        headerName: 'valideur1',
        field: 'user3.prenom',
        sortable: true,
        hide: true,
        suppressColumnsToolPanel: true,
      }, ,

      {
        headerName: 'valideur1',
        field: 'user4.prenom',
        sortable: true,
        hide: true,
        suppressColumnsToolPanel: true,
      },


    ];
  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }
    console.log("factionsData=>", this.factionsData);
    // this.getzones();
    // this.getfactions();
    // this.getusers();
  },
  methods: {
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    openCreate() {
      this.showForm("Create", {}, this.gridApi);
    },
    showForm(type, data, gridApi, width = "xl") {
      this.formKey++;
      this.formWidth = width;
      this.formState = type;
      this.formData = data;
      this.formGridApi = gridApi;
      this.$bvModal.show(this.formId);
    },
    onGridReady(params) {
      console.log("on demarre", params);
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false;
    },
    getzones() {
      this.axios
          .get("/api/zones")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // this.$store.commit('setIsLoading', false)
            }
            this.zonesData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },
    // getfactions() {
    //     this.axios.get('/api/factions').then((response) => {
    //         this.requette--
    //         if (this.requette == 0) {
    //             // this.$store.commit('setIsLoading', false)
    //         }
    //         this.factionsData = response.data

    //     }).catch(error => {
    //         console.log(error.response.data)
    //         // this.$store.commit('setIsLoading', false)
    //         this.$toast.error('Erreur survenue lors de la récuperation')
    //     })
    // },
    getusers() {
      this.axios
          .get("/api/users/type/2,3")
          .then((response) => {
            this.requette--;
            if (this.requette == 0) {
              // // this.$store.commit('setIsLoading', false)
            }
            this.usersData = response.data;
          })
          .catch((error) => {
            console.log(error.response.data);
            // // this.$store.commit('setIsLoading', false)
            this.$toast.error(
                "Erreur survenue lors de la récuperation"
            );
          });
    },
  },
};
</script>
